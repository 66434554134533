import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any
}

export type Comment = {
  __typename?: 'Comment'
  id: Scalars['ID']
  creationDate: Scalars['DateTime']
  modificationDate?: Maybe<Scalars['DateTime']>
  content: Scalars['String']
  user: User
}

export type Mutation = {
  __typename?: 'Mutation'
  postCreate: Post
  postUpdate: Post
  postComment: Post
  postStatus: Scalars['Boolean']
  postLike: Scalars['Boolean']
  postView: Scalars['Boolean']
  login: Session
}

export type MutationPostCreateArgs = {
  title: Scalars['String']
  resume?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  imagePath?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']>
  publish?: Maybe<Scalars['Boolean']>
}

export type MutationPostUpdateArgs = {
  title: Scalars['String']
  resume?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  imagePath?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']>
  publish?: Maybe<Scalars['Boolean']>
  id: Scalars['String']
}

export type MutationPostCommentArgs = {
  comment: Scalars['String']
  postId: Scalars['String']
}

export type MutationPostStatusArgs = {
  status: PostStatus
  id: Scalars['String']
}

export type MutationPostLikeArgs = {
  value: Scalars['Boolean']
  id: Scalars['String']
}

export type MutationPostViewArgs = {
  id: Scalars['String']
}

export type MutationLoginArgs = {
  redirectUri: Scalars['String']
  code: Scalars['String']
}

export type PaginationInputType = {
  sortField?: Maybe<Scalars['String']>
  sortOrder?: Maybe<PaginationOrder>
  page?: Maybe<Scalars['Float']>
  perPage?: Maybe<Scalars['Float']>
}

export enum PaginationOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type PaginationType = {
  __typename?: 'PaginationType'
  sortField: Scalars['String']
  sortOrder: PaginationOrder
  page: Scalars['Float']
  perPage: Scalars['Float']
  total: Scalars['Float']
}

export type Post = {
  __typename?: 'Post'
  id: Scalars['ID']
  creationDate: Scalars['DateTime']
  modificationDate?: Maybe<Scalars['DateTime']>
  userId: Scalars['String']
  user: User
  title: Scalars['String']
  resume: Scalars['String']
  imagePath?: Maybe<Scalars['String']>
  content: Scalars['String']
  tags: Array<Scalars['String']>
  nbView: Scalars['Float']
  nbLike: Scalars['Float']
  nbComment: Scalars['Float']
  status: PostStatus
  statusDate: Scalars['DateTime']
}

export type PostList = {
  __typename?: 'PostList'
  posts: Array<Post>
  pagination: PaginationType
}

export type PostListFilter = {
  author: Scalars['String']
}

export type PostMyListFilter = {
  status: Scalars['String']
}

export enum PostStatus {
  Draft = 'Draft',
  Published = 'Published',
}

export type Query = {
  __typename?: 'Query'
  postById: Post
  postList: PostList
  postSearch: PostList
  postByTag: PostList
  postMyList: PostList
  comments: Array<Comment>
  meLikes: Array<Scalars['String']>
  getLoginUrl: Scalars['String']
}

export type QueryPostByIdArgs = {
  id: Scalars['String']
}

export type QueryPostListArgs = {
  pagination?: Maybe<PaginationInputType>
  filter?: Maybe<PostListFilter>
}

export type QueryPostSearchArgs = {
  pagination?: Maybe<PaginationInputType>
  q: Scalars['String']
}

export type QueryPostByTagArgs = {
  pagination?: Maybe<PaginationInputType>
  tag: Scalars['String']
}

export type QueryPostMyListArgs = {
  pagination?: Maybe<PaginationInputType>
  filter?: Maybe<PostMyListFilter>
}

export type QueryCommentsArgs = {
  postId: Scalars['String']
}

export type QueryGetLoginUrlArgs = {
  redirectUri: Scalars['String']
}

export type Session = {
  __typename?: 'Session'
  username: Scalars['String']
  token: Scalars['String']
  expiresIn: Scalars['Float']
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  creationDate: Scalars['DateTime']
  modificationDate?: Maybe<Scalars['DateTime']>
  fullname: Scalars['String']
}

export type MeLikesQueryVariables = Exact<{ [key: string]: never }>

export type MeLikesQuery = { __typename?: 'Query'; meLikes: Array<string> }

export type MeLoginMutationVariables = Exact<{
  code: Scalars['String']
  redirectUri: Scalars['String']
}>

export type MeLoginMutation = {
  __typename?: 'Mutation'
  login: { __typename?: 'Session'; username: string; token: string; expiresIn: number }
}

export type MeLoginUrlQueryVariables = Exact<{
  redirectUri: Scalars['String']
}>

export type MeLoginUrlQuery = { __typename?: 'Query'; getLoginUrl: string }

export type PostMyListQueryVariables = Exact<{
  filter?: Maybe<PostMyListFilter>
  pagination?: Maybe<PaginationInputType>
}>

export type PostMyListQuery = {
  __typename?: 'Query'
  postMyList: {
    __typename?: 'PostList'
    posts: Array<{
      __typename?: 'Post'
      id: string
      title: string
      imagePath?: Maybe<string>
      resume: string
      tags: Array<string>
      status: PostStatus
      statusDate: any
      modificationDate?: Maybe<any>
      creationDate: any
      nbView: number
      nbLike: number
      nbComment: number
    }>
    pagination: {
      __typename?: 'PaginationType'
      page: number
      perPage: number
      sortField: string
      sortOrder: PaginationOrder
      total: number
    }
  }
}

export type PostByTagQueryVariables = Exact<{
  tag: Scalars['String']
  pagination?: Maybe<PaginationInputType>
}>

export type PostByTagQuery = {
  __typename?: 'Query'
  postByTag: {
    __typename?: 'PostList'
    posts: Array<{
      __typename?: 'Post'
      id: string
      title: string
      imagePath?: Maybe<string>
      resume: string
      tags: Array<string>
      statusDate: any
      modificationDate?: Maybe<any>
      nbView: number
      nbLike: number
      nbComment: number
      user: { __typename?: 'User'; fullname: string }
    }>
    pagination: {
      __typename?: 'PaginationType'
      page: number
      perPage: number
      sortField: string
      sortOrder: PaginationOrder
      total: number
    }
  }
}

export type PostCreateMutationVariables = Exact<{
  title: Scalars['String']
  resume?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']> | Scalars['String']
  imagePath?: Maybe<Scalars['String']>
  publish?: Maybe<Scalars['Boolean']>
}>

export type PostCreateMutation = { __typename?: 'Mutation'; postCreate: { __typename?: 'Post'; id: string } }

export type PostCreateCommentMutationVariables = Exact<{
  postId: Scalars['String']
  comment: Scalars['String']
}>

export type PostCreateCommentMutation = { __typename?: 'Mutation'; postComment: { __typename?: 'Post'; id: string } }

export type PostDetailQueryVariables = Exact<{
  id: Scalars['String']
}>

export type PostDetailQuery = {
  __typename?: 'Query'
  postById: {
    __typename?: 'Post'
    id: string
    title: string
    imagePath?: Maybe<string>
    resume: string
    content: string
    tags: Array<string>
    status: PostStatus
    statusDate: any
    modificationDate?: Maybe<any>
    nbLike: number
    nbView: number
    nbComment: number
    user: { __typename?: 'User'; fullname: string }
  }
}

export type PostLikeMutationVariables = Exact<{
  id: Scalars['String']
  value: Scalars['Boolean']
}>

export type PostLikeMutation = { __typename?: 'Mutation'; postLike: boolean }

export type LoadCommentQueryVariables = Exact<{
  postId: Scalars['String']
}>

export type LoadCommentQuery = {
  __typename?: 'Query'
  comments: Array<{
    __typename?: 'Comment'
    id: string
    creationDate: any
    content: string
    user: { __typename?: 'User'; fullname: string }
  }>
}

export type PostSearchQueryVariables = Exact<{
  q: Scalars['String']
  pagination?: Maybe<PaginationInputType>
}>

export type PostSearchQuery = {
  __typename?: 'Query'
  postSearch: {
    __typename?: 'PostList'
    posts: Array<{
      __typename?: 'Post'
      id: string
      title: string
      imagePath?: Maybe<string>
      resume: string
      tags: Array<string>
      statusDate: any
      modificationDate?: Maybe<any>
      nbView: number
      nbLike: number
      nbComment: number
      user: { __typename?: 'User'; fullname: string }
    }>
    pagination: {
      __typename?: 'PaginationType'
      page: number
      perPage: number
      sortField: string
      sortOrder: PaginationOrder
      total: number
    }
  }
}

export type PostStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: PostStatus
}>

export type PostStatusMutation = { __typename?: 'Mutation'; postStatus: boolean }

export type PostUpdateMutationVariables = Exact<{
  id: Scalars['String']
  title: Scalars['String']
  resume?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']> | Scalars['String']
  imagePath?: Maybe<Scalars['String']>
  publish?: Maybe<Scalars['Boolean']>
}>

export type PostUpdateMutation = { __typename?: 'Mutation'; postUpdate: { __typename?: 'Post'; id: string } }

export type PostViewMutationVariables = Exact<{
  id: Scalars['String']
}>

export type PostViewMutation = { __typename?: 'Mutation'; postView: boolean }

export type PostListQueryVariables = Exact<{
  filter?: Maybe<PostListFilter>
  pagination?: Maybe<PaginationInputType>
}>

export type PostListQuery = {
  __typename?: 'Query'
  postList: {
    __typename?: 'PostList'
    posts: Array<{
      __typename?: 'Post'
      id: string
      title: string
      imagePath?: Maybe<string>
      resume: string
      tags: Array<string>
      statusDate: any
      modificationDate?: Maybe<any>
      nbView: number
      nbLike: number
      nbComment: number
      user: { __typename?: 'User'; fullname: string }
    }>
    pagination: {
      __typename?: 'PaginationType'
      page: number
      perPage: number
      sortField: string
      sortOrder: PaginationOrder
      total: number
    }
  }
}

export const MeLikesDocument = gql`
  query meLikes {
    meLikes
  }
`
export const MeLoginDocument = gql`
  mutation meLogin($code: String!, $redirectUri: String!) {
    login(code: $code, redirectUri: $redirectUri) {
      username
      token
      expiresIn
    }
  }
`
export const MeLoginUrlDocument = gql`
  query meLoginUrl($redirectUri: String!) {
    getLoginUrl(redirectUri: $redirectUri)
  }
`
export const PostMyListDocument = gql`
  query postMyList($filter: PostMyListFilter, $pagination: PaginationInputType) {
    postMyList(filter: $filter, pagination: $pagination) {
      posts {
        id
        title
        imagePath
        resume
        tags
        status
        statusDate
        modificationDate
        creationDate
        nbView
        nbLike
        nbComment
      }
      pagination {
        page
        perPage
        sortField
        sortOrder
        total
      }
    }
  }
`
export const PostByTagDocument = gql`
  query postByTag($tag: String!, $pagination: PaginationInputType) {
    postByTag(tag: $tag, pagination: $pagination) {
      posts {
        id
        title
        imagePath
        resume
        tags
        statusDate
        modificationDate
        nbView
        nbLike
        nbComment
        user {
          fullname
        }
      }
      pagination {
        page
        perPage
        sortField
        sortOrder
        total
      }
    }
  }
`
export const PostCreateDocument = gql`
  mutation postCreate(
    $title: String!
    $resume: String
    $content: String
    $tags: [String!]!
    $imagePath: String
    $publish: Boolean
  ) {
    postCreate(
      title: $title
      resume: $resume
      content: $content
      tags: $tags
      imagePath: $imagePath
      publish: $publish
    ) {
      id
    }
  }
`
export const PostCreateCommentDocument = gql`
  mutation postCreateComment($postId: String!, $comment: String!) {
    postComment(postId: $postId, comment: $comment) {
      id
    }
  }
`
export const PostDetailDocument = gql`
  query postDetail($id: String!) {
    postById(id: $id) {
      id
      title
      imagePath
      resume
      content
      tags
      status
      statusDate
      modificationDate
      nbLike
      nbView
      nbComment
      user {
        fullname
      }
    }
  }
`
export const PostLikeDocument = gql`
  mutation postLike($id: String!, $value: Boolean!) {
    postLike(id: $id, value: $value)
  }
`
export const LoadCommentDocument = gql`
  query loadComment($postId: String!) {
    comments(postId: $postId) {
      id
      creationDate
      content
      user {
        fullname
      }
    }
  }
`
export const PostSearchDocument = gql`
  query postSearch($q: String!, $pagination: PaginationInputType) {
    postSearch(q: $q, pagination: $pagination) {
      posts {
        id
        title
        imagePath
        resume
        tags
        statusDate
        modificationDate
        nbView
        nbLike
        nbComment
        user {
          fullname
        }
      }
      pagination {
        page
        perPage
        sortField
        sortOrder
        total
      }
    }
  }
`
export const PostStatusDocument = gql`
  mutation postStatus($id: String!, $status: PostStatus!) {
    postStatus(id: $id, status: $status)
  }
`
export const PostUpdateDocument = gql`
  mutation postUpdate(
    $id: String!
    $title: String!
    $resume: String
    $content: String
    $tags: [String!]!
    $imagePath: String
    $publish: Boolean
  ) {
    postUpdate(
      id: $id
      title: $title
      resume: $resume
      content: $content
      tags: $tags
      imagePath: $imagePath
      publish: $publish
    ) {
      id
    }
  }
`
export const PostViewDocument = gql`
  mutation postView($id: String!) {
    postView(id: $id)
  }
`
export const PostListDocument = gql`
  query postList($filter: PostListFilter, $pagination: PaginationInputType) {
    postList(filter: $filter, pagination: $pagination) {
      posts {
        id
        title
        imagePath
        resume
        tags
        statusDate
        modificationDate
        nbView
        nbLike
        nbComment
        user {
          fullname
        }
      }
      pagination {
        page
        perPage
        sortField
        sortOrder
        total
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    meLikes(variables?: MeLikesQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<MeLikesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MeLikesQuery>(MeLikesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'meLikes'
      )
    },
    meLogin(
      variables: MeLoginMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<MeLoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MeLoginMutation>(MeLoginDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'meLogin'
      )
    },
    meLoginUrl(
      variables: MeLoginUrlQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<MeLoginUrlQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MeLoginUrlQuery>(MeLoginUrlDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'meLoginUrl'
      )
    },
    postMyList(
      variables?: PostMyListQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostMyListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostMyListQuery>(PostMyListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postMyList'
      )
    },
    postByTag(
      variables: PostByTagQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostByTagQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostByTagQuery>(PostByTagDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'postByTag'
      )
    },
    postCreate(
      variables: PostCreateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostCreateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostCreateMutation>(PostCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postCreate'
      )
    },
    postCreateComment(
      variables: PostCreateCommentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostCreateCommentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostCreateCommentMutation>(PostCreateCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postCreateComment'
      )
    },
    postDetail(
      variables: PostDetailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostDetailQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostDetailQuery>(PostDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postDetail'
      )
    },
    postLike(
      variables: PostLikeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostLikeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostLikeMutation>(PostLikeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postLike'
      )
    },
    loadComment(
      variables: LoadCommentQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<LoadCommentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadCommentQuery>(LoadCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'loadComment'
      )
    },
    postSearch(
      variables: PostSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostSearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostSearchQuery>(PostSearchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postSearch'
      )
    },
    postStatus(
      variables: PostStatusMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostStatusMutation>(PostStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postStatus'
      )
    },
    postUpdate(
      variables: PostUpdateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostUpdateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostUpdateMutation>(PostUpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postUpdate'
      )
    },
    postView(
      variables: PostViewMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostViewMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostViewMutation>(PostViewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postView'
      )
    },
    postList(variables?: PostListQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<PostListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostListQuery>(PostListDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'postList'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
