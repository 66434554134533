/* eslint-disable no-console */
import * as React from 'react'
import { sdk } from '@api/api'
import Logger from '@utils/logger'
import Button from '@ui/components/button/Button'
import * as session from '@store/session'

const getRedirectUri = () => {
  return window.location.href.substr(0, window.location.href.indexOf('/', 10))
}
const REDIRECT_AFTER = 'redirectAfter'

const Login = () => {
  const [state, setState] = React.useState<'loading' | 'waiting' | 'checking' | 'error'>('loading')

  const login = () => {
    const redirectAfter = sessionStorage.getItem(REDIRECT_AFTER) || window.location.href
    sessionStorage.clear()
    sessionStorage.setItem(REDIRECT_AFTER, redirectAfter)
    sdk
      .meLoginUrl({ redirectUri: getRedirectUri() })
      .then((res) => {
        window.location.href = res.getLoginUrl
      })
      .catch((err) => {
        Logger.error(err)
        setState('error')
      })
  }

  React.useEffect(() => {
    const idx = window.location.href.indexOf('code=')
    if (idx > -1) {
      const code = new URLSearchParams(window.location.href.substr(idx)).get('code')
      if (code) {
        setState('checking')
        sdk
          .meLogin({ code, redirectUri: getRedirectUri() })
          .then((r) => {
            const redirectAfter = sessionStorage.getItem(REDIRECT_AFTER) || '/'
            sessionStorage.removeItem(REDIRECT_AFTER)
            //TODO: affecter la session va enlever cet écran (cf App) donc pas idéal pour le redirect après
            session.actions.login({
              fullname: r.login.username,
              token: r.login.token,
              expiresAtMS: Date.now() + r.login.expiresIn * 1000 - 5 * 60 * 1000,
            })
            window.location.href = redirectAfter
          })
          .catch((err) => {
            Logger.error('Unable to log in', err)
            setState('error')
          })
        return
      }
    }
    setState('waiting')
  }, [])

  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      {state === 'error' && <div className="text-red-600 font-bold pb-3">Oups, une erreur ... Réessayer ?</div>}
      <Button
        onClick={login}
        loading={state === 'loading' || state === 'checking'}
        label="Se connecter avec Microsoft"
      />
    </div>
  )
}

export default Login
