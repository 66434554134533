type IconProps = {
  color?: string
}
const DEFAULT_COLOR = 'gray'

export const Warning = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
)

export const Eye = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
)

export const ThumbUp = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
    />
  </svg>
)

export const ChevronRight = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path d="M9 5L16 12L9 19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const Comment = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
    />
  </svg>
)

export const Cross = ({ color = DEFAULT_COLOR }: IconProps) => (
  <svg
    className={`h-6 w-6 text-${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
    />
  </svg>
)

export type IconName = 'Warning' | 'ChevronRight' | 'Eye' | 'ThumbUp' | 'Comment' | 'Cross'
type Props = {
  name: IconName
} & IconProps

const Icons = ({ name, ...rest }: Props) => {
  if (name === 'Warning') {
    return <Warning {...rest} />
  }
  if (name === 'ChevronRight') {
    return <ChevronRight {...rest} />
  }
  if (name === 'Eye') {
    return <Eye {...rest} />
  }
  if (name == 'ThumbUp') {
    return <ThumbUp {...rest} />
  }
  if (name == 'Comment') {
    return <Comment {...rest} />
  }
  if (name == 'Cross') {
    return <Cross {...rest} />
  }
  return <></>
}

export default Icons
