import * as React from 'react'
import * as api from '@api/api'
import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react'

const likeStore = createStore([] as string[])
const setLikes = createEvent<string[]>('setlikes')
likeStore.on(setLikes, (_, values) => values)
let loaded = false

const useLikes = () => {
  const meLikes = useStore(likeStore)
  React.useEffect(() => {
    if (!loaded) {
      loaded = true
      api.sdk.meLikes().then((r) => {
        setLikes(r.meLikes)
      })
    }
  }, [])

  return React.useMemo(() => {
    const updateLikes = (changedId: string, newValue: string[]) => {
      const prevValue = meLikes
      let localUpdateTimeout: NodeJS.Timeout | undefined = setTimeout(() => {
        setLikes(newValue)
        localUpdateTimeout = undefined
      }, 250)
      api.sdk.postLike({ id: changedId, value: newValue.includes(changedId) }).then((l) => {
        if (l.postLike) {
          if (!localUpdateTimeout) {
            setLikes([...newValue]) // forcer une mise à jour
          }
        } else {
          // Retour arriere
          setLikes(prevValue)
        }
      })
    }
    const ret = {
      likes: meLikes,
      add: (id: string) => {
        updateLikes(id, [id, ...meLikes.filter((v) => v !== id)])
      },
      remove: (id: string) => {
        updateLikes(
          id,
          meLikes.filter((v) => v !== id)
        )
      },
      toggle: (id: string) => {
        const ilike = meLikes.includes(id)
        if (ilike) {
          ret.remove(id)
        } else {
          ret.add(id)
        }
      },
      like: (id: string) => ret.likes.includes(id),
    }
    return ret
  }, [meLikes])
}

export default useLikes
