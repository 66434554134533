import { withPersist } from './persistStore'
import { createStore, createEvent } from 'effector'

export type SessionState = {
  user?: {
    fullname: string
    token: string
    expiresAtMS: number
  }
}

const initialState: SessionState = {}

export const store = withPersist(createStore<SessionState>(initialState, { name: 'authv2' }))

const login = createEvent<SessionState['user']>('login')
const logout = createEvent('logout')

store.on(login, (_, payload) => ({ user: payload })).on(logout, () => initialState)

export const actions = {
  login,
  logout,
}
