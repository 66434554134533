import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { navigate } from 'hookrouter'
import { sdk } from '@api/api'
import Logger from '@utils/logger'
import Button from '@ui/components/button/Button'
import TagEditor from './TagEditor'
import { push } from '@utils/Toast'
import { Post, PostStatus } from '@api/api.generated'

type Props = {
  id: string
}

const Edit = ({ initialValues, id }: { initialValues: Values & { imagePathFile?: string }; id: string }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isSubmitting },

    reset,
  } = useForm({
    defaultValues: { ...initialValues, imagePathFile: '' },
  })
  const publish = React.useRef(false)

  React.useEffect(() => {
    reset({ ...initialValues, imagePathFile: '' })
  }, [initialValues, reset])

  const onSubmit = async (data: Values & { imagePathFile?: string }) => {
    push('loading', 'Enregistrement en cours')
    const post = {
      title: data.title,
      content: data.content,
      resume: data.resume,
      imagePath: data.imagePathFile === '' ? undefined : data.imagePathFile,
      tags: data.tags,
      publish: publish.current,
    }
    if (id === 'new') {
      return sdk
        .postCreate(post)
        .then((r) => {
          push('success', 'Enregistrement réussi !')
          navigate(`/edit/${r.postCreate.id}`, true)
        })
        .catch((err) => {
          push('error', "L'enregistrement a échoué ...")
          Logger.error(err)
        })
    } else {
      return sdk
        .postUpdate({
          id,
          ...post,
        })
        .then(() => {
          push('success', 'Enregistrement réussi !')
        })
        .catch((err) => {
          push('error', "L'enregistrement a échoué ...")
          Logger.error(err)
        })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="space-y-4 text-gray-700 sm:text-lg">
          <Field error={!!errors.title} label="Titre">
            <input
              type="text"
              className={fieldClassName}
              name="title"
              ref={register({ required: false, minLength: 3 })}
            />
          </Field>
          <Field error={!!errors.resume && !!dirtyFields.resume} label="Résumé">
            <textarea className={fieldClassName} name="resume" ref={register()} />
          </Field>
          <Field error={!!errors.imagePathFile} label="Image">
            <Controller
              name="imagePathFile"
              control={control}
              rules={{ required: id === 'new' }}
              render={({ onChange, value }) => (
                <>
                  <input
                    className={fieldClassName}
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      toBase64(e)
                        .then((v) => onChange(v))
                        .catch((err) => Logger.error('Unable to load image', err))
                    }
                  />
                  {value && (
                    <img
                      src={value}
                      style={{ objectFit: 'contain', maxHeight: 80, maxWidth: 80, width: 'auto', height: 'auto' }}
                    />
                  )}
                  {!value && initialValues.imagePath && (
                    <img
                      src={initialValues.imagePath}
                      style={{ objectFit: 'contain', maxHeight: 80, maxWidth: 80, width: 'auto', height: 'auto' }}
                    />
                  )}
                </>
              )}
            />
          </Field>
          <Field error={!!errors.tags && !!dirtyFields.tags} label="Tags">
            <Controller
              name="tags"
              control={control}
              rules={{}}
              render={({ onChange, value }) => <TagEditor tags={value} onChange={onChange} />}
            />
          </Field>
          <Field error={!!errors.content && !!dirtyFields.content} label="Contenu">
            <Controller
              name="content"
              control={control}
              rules={{}}
              render={({ onChange, value }) => <ReactQuill theme="snow" value={value} onChange={onChange} />}
            />
          </Field>
          <div>
            <Button
              type="submit"
              loading={isSubmitting}
              label={id === 'new' ? 'Créer' : 'Enregistrer'}
              onClick={() => (publish.current = false)}
            />
            {(id === 'new' || initialValues.status === PostStatus.Draft) && (
              <Button
                type="submit"
                className="ml-5"
                loading={isSubmitting}
                label={id === 'new' ? 'Créer & Publier' : 'Enregistrer & Publier'}
                onClick={() => (publish.current = true)}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

const toBase64 = async (e: React.ChangeEvent<HTMLInputElement>): Promise<string | undefined> => {
  if (!e.target.files || e.target.files.length === 0) {
    return undefined
  }
  const v = e.target.files[0]
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(v)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

const fieldClassName =
  'px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'

type FieldProps = {
  label: string
  children: React.ReactNode
  error: boolean
}

const Field = ({ label, children, error }: FieldProps) => (
  <div className={`flex flex-col${error ? ' text-red-700 border-red-900 border-2 p-2' : ''}`}>
    <label className="leading-loose">{label}</label>
    {children}
  </div>
)

type Values = {
  title: string
  resume: string
  content?: string
  imagePath?: string | null
  tags: string[]
  status: Post['status']
}

const initialEmpyValues: Values & { imagePathFile?: string } = {
  title: '',
  content: '',
  resume: '',
  tags: [],
  status: PostStatus.Draft,
}

const EditWithLoader = ({ id }: Props) => {
  const [state, setState] = React.useState<'loading' | 'loaded' | 'error'>('loading')
  const [initialValues, setInitialValues] = React.useState<Values>()
  React.useEffect(() => {
    setState('loading')
    if (id !== 'new') {
      sdk
        .postDetail({ id })
        .then((r) => {
          setInitialValues(r.postById)
          setState('loaded')
        })
        .catch((err) => {
          Logger.error('Unable to load post', err)
          setState('error')
        })
    } else {
      setInitialValues(undefined)
      setState('loaded')
    }
  }, [id])

  if (state === 'loading') {
    return <div>Loading</div>
  }

  if (state === 'error') {
    return <div>ERROR</div>
  }

  return <Edit key={id} initialValues={initialValues || initialEmpyValues} id={id} />
}

export default EditWithLoader
