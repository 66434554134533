import * as React from 'react'
import { A, usePath, navigate, setQueryParams } from 'hookrouter'

const menuItems = [
  {
    path: '/',
    label: 'Accueil',
    exact: true,
  },
  {
    path: '/me',
    label: 'Mes posts',
    exact: false,
  },
  {
    path: '/edit/new',
    label: 'Nouveau post',
    exact: true,
  },
]

const Navbar = () => {
  const [q, setQ] = React.useState<string>()
  const location = usePath()

  return (
    <nav className="bg-white shadow">
      <div className="mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <A href="/" className="flex-shrink-0 flex items-center">
              <img className="block h-8 w-auto" src="/favicon.png" alt="Cardiweb" />
              <span className="font-bold text-xl pl-3 text-cw">MyBlog</span>
            </A>
            <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
              {menuItems.map((m) => {
                const isCurrent = m.exact ? location === m.path : location.startsWith(m.path)
                return (
                  <A
                    key={m.path}
                    href={m.path}
                    className={`inline-flex items-center  px-1 pt-1 border-b-2 text-sm font-medium ${
                      isCurrent
                        ? 'border-indigo-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    }`}
                  >
                    {m.label}
                  </A>
                )
              })}
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Rechercher
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Rechercher"
                  type="search"
                  value={q}
                  onKeyDown={({ code }) => {
                    if (q && (code === 'Enter' || code === 'NumpadEnter')) {
                      navigate('/search')
                      setQueryParams({ q })
                    }
                  }}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden" id="mobile-menu">
        <div className="pt-2 pb-3 space-y-1">
          {menuItems.map((m) => {
            const isCurrent = m.exact ? location === m.path : location.startsWith(m.path)
            return (
              <A
                key={m.path}
                href={m.path}
                className={` block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isCurrent
                    ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
                }`}
              >
                {m.label}
              </A>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
