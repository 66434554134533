/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
type LOGGER_FUNCTION = typeof console.log

type LOGGER = {
  debug: LOGGER_FUNCTION
  info: LOGGER_FUNCTION
  error: LOGGER_FUNCTION
}

const LoggerFactory: (level: string) => LOGGER_FUNCTION = (level) => {
  if (level === 'debug' && process.env.NODE_ENV !== 'development') {
    return () => {
      /* noop */
    }
  }
  if (level === 'error') {
    return (message?: any, ...params: any[]) => console.error(`${level}: ${message || ''}`, ...params)
  }
  return (message?: any, ...params: any[]) => console.error(`${level}: ${message || ''}`, ...params)
}

const Logger: LOGGER = {
  debug: LoggerFactory('debug'),
  info: LoggerFactory('info'),
  error: LoggerFactory('error'),
}

export default Logger
