import * as React from 'react'
import * as sessionStore from '@store/session'
import { useStore } from 'effector-react'
import { usePath, HookRouter, useQueryParams, useRoutes } from 'hookrouter'
import Navbar from '@ui/components/layout/navbar'

import ListPosts from '@ui/screens/listposts/ListPosts'
import DetailPost from '@ui/screens/detailpost/DetailPost'
import EditPost from '@ui/screens/editpost/EditPost'
import Me from '@ui/screens/me/Me'

import Login from '@ui/screens/login/login'
import { setHandler, Toast } from '@utils/Toast'
import { Cross } from '@ui/components/icons/Icon'

const routes = {
  '/post/:id': (params: HookRouter.QueryParams) => <DetailPost id={params.id} />,
  '/edit/:id': (params: HookRouter.QueryParams) => <EditPost id={params.id} />,
  '/me': () => <Me />,
  '/tag/:tag': (params: HookRouter.QueryParams) => <ListPosts key={params.tag} tag={params.tag} />,
  '/search': () => <ListPosts key="search" />,
  '/': () => <ListPosts key="home" />,
}

const NotFound = () => <div>Oups</div>

const AppWithRouter = () => {
  const [queryParams] = useQueryParams()
  const routeResult = useRoutes(routes)
  const [toast, setToast] = React.useState<Toast>()
  React.useEffect(() => {
    setHandler((t) => {
      setToast(t)
      if (t.type !== 'loading') {
        setTimeout(() => {
          setToast((p) => (p === t ? undefined : p))
        }, 5000)
      }
    })
    return () => {
      setHandler(undefined)
    }
  }, [])
  usePath() // Pour etre mis à jour au changement de page :(
  return (
    <>
      {toast && (
        <div className="absolute right-0 top-0 m-5">
          <div
            className={`flex items-center ${
              toast.type === 'loading' ? 'bg-yellow-600' : toast.type === 'error' ? 'bg-red-500' : 'bg-green-500'
            } border-l-4 border-green-700 py-2 px-3 shadow-md mb-2`}
          >
            <div className="text-white max-w-xs ">
              {toast.text}
              {toast.type !== 'loading' && (
                <button
                  onClick={() => setToast(undefined)}
                  className="w-10 h-10 inline-block align-middle focus:outline-none"
                >
                  <Cross color="text-white" />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="relative">{routeResult || <NotFound />}</div>
    </>
  )
}

const App = () => {
  const session = useStore(sessionStore.store)
  if (!session.user || session.user.expiresAtMS < Date.now()) {
    return <Login />
  }

  return (
    <div className="flex flex-col h-screen text-black">
      <Navbar />
      <main style={{ backgroundColor: 'white' }} className="flex-1 overflow-y-auto p-5 px-5">
        <AppWithRouter />
      </main>
    </div>
  )
}

export default App
