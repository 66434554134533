import { sdk } from '@api/api'
import { PostMyListQuery, PostStatus } from '@api/api.generated'
import Logger from '@utils/logger'
import * as React from 'react'
import { navigate } from 'hookrouter'
import Tag from '@ui/components/tag/Tag'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import Button from '@ui/components/button/Button'
import PostMetrics from '@ui/components/post/PostMetrics'

const Me = () => {
  const [state, setState] = React.useState<'loading' | 'loaded' | 'error'>('loading')
  const [changingState, setchangingState] = React.useState<string>()
  const [posts, setPosts] = React.useState<PostMyListQuery['postMyList']>()
  const reload = () => {
    return sdk
      .postMyList()
      .then((r) => {
        setPosts(r.postMyList)
        setState('loaded')
      })
      .catch((err) => {
        Logger.error('Unable to load posts: ', err)
        setState('error')
      })
  }
  const setPublishedState = (id: string, published: boolean) => {
    setchangingState(id)
    sdk
      .postStatus({ id, status: published ? PostStatus.Published : PostStatus.Draft })
      .then(reload)
      .then(() => {
        setchangingState(undefined)
      })
      .catch(() => {
        setState('error')
        setchangingState(undefined)
      })
  }
  React.useEffect(() => {
    reload()
  }, [])

  if (state === 'loading') {
    return <div>Loading</div>
  }
  if (!posts || state === 'error') {
    return <div>Error</div>
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <TH>Titre</TH>
                  <TH>Status</TH>
                  <TH>Dernière modififcation</TH>
                  <TH>Tags</TH>
                  <TH>Stats</TH>
                  <TH>&nbsp;</TH>
                </tr>
              </thead>
              <tbody>
                {posts.posts.map((p, i) => (
                  <tr key={p.id} className={i & 1 ? 'bg-white' : 'bg-gray-50'}>
                    <TD>{p.title}</TD>
                    <TD>
                      {p.status === PostStatus.Draft ? (
                        <Tag label="Bouillon" />
                      ) : (
                        <Tag label="Publié" color="green-100" />
                      )}
                    </TD>
                    <TD>
                      {formatDistanceToNow(parseISO(p.modificationDate || p.creationDate), {
                        addSuffix: true,
                        locale: fr,
                      })}
                    </TD>
                    <TD>{p.tags.join(',')}</TD>
                    <TD className="flex flex-row">
                      <PostMetrics post={p} likeEnabled={false} />
                    </TD>
                    <TD className="text-right">
                      <Button
                        className="ml-2"
                        onClick={() => setPublishedState(p.id, p.status === PostStatus.Draft)}
                        label={p.status === PostStatus.Draft ? 'Publier' : 'Dépublier'}
                        loading={changingState === p.id}
                      />
                      <Button className="ml-2" onClick={() => navigate(`/edit/${p.id}`)} label="Editer" />
                      <Button className="ml-2" onClick={() => navigate(`/post/${p.id}`)} label="Voir" />
                    </TD>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

type CellProp = { className?: string; children: string | React.ReactNode }
const TH = ({ className, children }: CellProp) => (
  <th
    scope="col"
    className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${className}`}
  >
    {children}
  </th>
)
const TD = ({ className, children }: CellProp) => (
  <td className={`px-6 py-4 text-sm text-gray-500 ${className}`}>{children}</td>
)

export default Me
