import { GraphQLClient } from 'graphql-request'
import { getSdk } from './api.generated'
import * as sessionStore from '@store/session'

const graphQLClient = new GraphQLClient('/graphql', {})
if (sessionStore.store.getState().user?.token) {
  graphQLClient.setHeader('authorization', `Bearer ${sessionStore.store.getState().user?.token}`)
}
sessionStore.store.subscribe((state) => {
  if (state.user?.token) {
    graphQLClient.setHeader('authorization', `Bearer ${state.user.token}`)
  } else {
    graphQLClient.setHeader('authorization', '')
  }
})

export const sdk = getSdk(graphQLClient)
