const Tag = ({ label, onClick, color = 'cw-100' }: { label: string; onClick?: () => void; color?: string }) => {
  if (onClick) {
    return (
      <a
        href="#"
        onClick={onClick}
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color} text-cw-dark`}
      >
        {label}
      </a>
    )
  }
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color} text-cw-dark`}
    >
      {label}
    </span>
  )
}

export default Tag
