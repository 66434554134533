import { ThumbUp, Eye, Comment } from '@components/icons/Icon'
import useLikes from './useLikes'
import { Post } from '@api/api.generated'

type Props = {
  post: Pick<Post, 'nbView' | 'nbLike' | 'id' | 'nbComment'>
  likeEnabled?: boolean
}

const PostMetrics = ({ post, likeEnabled = true }: Props) => {
  const meLikes = useLikes()
  const ilike = meLikes.likes.includes(post.id)
  return (
    <>
      <Eye />
      &nbsp;&nbsp;{post.nbView}&nbsp;&nbsp;
      {likeEnabled ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            meLikes.toggle(post.id)
          }}
        >
          <ThumbUp color={ilike ? 'cw' : 'black'} />
        </a>
      ) : (
        <ThumbUp color={ilike ? 'cw' : 'black'} />
      )}
      &nbsp;&nbsp;{post.nbLike}&nbsp;&nbsp;
      <Comment />
      &nbsp;&nbsp;{post.nbComment}
    </>
  )
}

export default PostMetrics
