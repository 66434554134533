import { Cross } from '@ui/components/icons/Icon'
import * as React from 'react'

type Props = {
  tags: string[]
  onChange: (tags: string[]) => void
}

const TagEditor = ({ tags, onChange }: Props) => {
  const [value, setValue] = React.useState('')
  return (
    <div className="w-full">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
        onKeyDown={(k) => {
          if (k.code === 'Space' || k.key === ',' || k.code === 'Enter' || k.code === 'NumpadEnter') {
            if (value.length > 0) {
              const t = value.toUpperCase()
              setValue('')
              onChange([...tags.filter((v) => v !== t), t])
            }
            k.preventDefault()
          } else if (
            (k.key >= 'a' && k.key <= 'z') ||
            (k.key >= 'A' && k.key <= 'Z') ||
            (k.key >= '0' && k.key <= '9')
          ) {
          } else {
            k.preventDefault()
          }
        }}
        onBlur={() => {
          if (value) {
            const t = value.toUpperCase()
            setValue('')
            onChange([...tags.filter((v) => v !== t), t])
          }
        }}
      />
      {tags.map((t) => (
        <div key={t} className="bg-indigo-100 inline-flex items-center text-sm rounded mt-2 mr-1">
          <span className="ml-2 mr-1 leading-relaxed truncate max-w-xs">{t}</span>
          <button
            onClick={() => onChange(tags.filter((v) => v !== t))}
            className="w-6 h-8 inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none"
          >
            <Cross />
          </button>
        </div>
      ))}
    </div>
  )
}

export default TagEditor
