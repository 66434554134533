/**
 * Afficher des toast depuis n'importe ou.
 * A chaque nouveau message, le précédent est immédiatement remplacé. Donc un seul à la fois.
 * Les message disparaissent au bout de quelques secondes ou si on appuie sur la croix de fermeture.
 * Sauf pour les messages loading qui attendent d'être replacés.
 *
 * Un seul handler à la fois : si on ré-appelle setHandler, le précédent ne recevra plus rien.
 *
 * Les messages ne sont pas enregistrés donc si pas de handler au moment de leur arrivée, ils seront perdus.
 */

export type Toast = {
  type: 'success' | 'error' | 'loading'
  text: string
}
type Handler = (msg: Toast) => void
let handler: Handler | undefined = undefined

export const push = (type: Toast['type'], text: Toast['text']) => {
  if (handler) {
    handler({
      type,
      text,
    })
  }
}

export const setHandler = (h: Handler | undefined) => {
  handler = h
}
