import * as React from 'react'
import * as api from '@api/api'
import { PostListQuery } from '@api/api.generated'
import Logger from '@utils/logger'
import { A, useQueryParams, usePath, navigate } from 'hookrouter'
import Icons from '@ui/components/icons/Icon'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import Tag from '@components/tag/Tag'
import PostMetrics from '@ui/components/post/PostMetrics'

type Props = {
  tag?: string
}

const ListPosts = ({ tag }: Props) => {
  const [postsList, setPostsList] = React.useState<PostListQuery['postList']>()
  const [status, setStatus] = React.useState<'loading' | 'loaded' | 'error'>('loading')
  const [queryParams] = useQueryParams()
  const path = usePath()
  const q = path === '/search' && queryParams?.q
  React.useEffect(() => {
    let req: Promise<PostListQuery['postList']> | undefined
    if (tag) {
      req = api.sdk.postByTag({ tag }).then((r) => r.postByTag)
    } else if (q) {
      req = api.sdk.postSearch({ q }).then((r) => r.postSearch)
    } else {
      req = api.sdk.postList().then((r) => r.postList)
    }
    req
      .then((l) => {
        setPostsList(l)
        setStatus('loaded')
      })
      .catch((err) => {
        Logger.error('Unable to load posts', err)
        setStatus('error')
      })
  }, [tag, queryParams])

  if (status === 'loading') {
    return <div>Loading</div>
  }
  if (!postsList || status === 'error') {
    return <div>Error</div>
  }

  return (
    <section className="w-full flex flex-col items-center px-3">
      {tag ? (
        <h1>
          Article taggés avec <b>{tag}</b>
        </h1>
      ) : q ? (
        <h1>
          Résultats pour <b>{q}</b>
        </h1>
      ) : undefined}
      {postsList.posts.map((p) => {
        return (
          <article key={p.id} className="flex flex-row shadow my-4 w-full">
            <A
              href={`/post/${p.id}`}
              className="w-80 h-auto bg-contain bg-no-repeat bg-center overflow-hidden"
              style={{ backgroundImage: `url('${p.imagePath}')` }}
            ></A>
            <div className="bg-white flex flex-col justify-start pl-6 flex-1">
              <A href={`/post/${p.id}`} className="text-3xl font-bold hover:text-gray-700 pb-1">
                {p.title}
              </A>
              <span className="flex flex-row">
                {p.tags.map((t, i) => (
                  <>
                    {i > 0 && <span>,&nbsp;</span>}
                    <Tag onClick={() => navigate(`/tag/${t}`)} label={t} />
                  </>
                ))}
              </span>
              <p className="text-sm pb-3">
                Par{' '}
                <a href="#" className="font-semibold hover:text-gray-800">
                  {p.user.fullname}
                </a>
                , {formatDistanceToNow(parseISO(p.statusDate), { addSuffix: true, locale: fr })}
              </p>
              <A href={`/post/${p.id}`} className="pb-6">
                {p.resume}
              </A>

              <div className="flex">
                <div className="flex flex-1">
                  <PostMetrics post={p} likeEnabled={false} />
                </div>
                <A href={`/post/${p.id}`} className="uppercase text-gray-800 hover:text-black flex">
                  Lire la suite <Icons name="ChevronRight" />
                </A>
              </div>
            </div>
          </article>
        )
      })}
    </section>
  )
}

export default ListPosts
