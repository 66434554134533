import * as React from 'react'
import Icons, { IconName } from '../icons/Icon'

type Props = {
  icon?: IconName
  label: string
  onClick?: () => void
  loading?: boolean
  className?: string
  type?: 'button' | 'submit'
}

export default ({ type = 'button', icon, label, onClick, loading, className }: Props) => {
  return (
    <button
      type={type}
      className={`bg-gray-300 hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center ${
        className ?? ''
      }`}
      onClick={onClick}
      disabled={loading}
    >
      {icon && <Icons name={icon} />}
      {label}
    </button>
  )
}
