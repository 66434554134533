import * as React from 'react'
import * as api from '@api/api'
import Logger from '@utils/logger'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import { PostDetailQuery, LoadCommentQuery } from '@api/api.generated'
import Button from '@ui/components/button/Button'
import { push } from '@utils/Toast'
import useLikes from '@ui/components/post/useLikes'
import PostMetrics from '@ui/components/post/PostMetrics'

const ListPosts = ({ id }: { id: string }) => {
  const [post, setPost] = React.useState<PostDetailQuery['postById']>()
  const [comments, setComments] = React.useState<LoadCommentQuery['comments']>()
  const [status, setStatus] = React.useState<'loading' | 'loaded' | 'error'>('loading')
  const meLikes = useLikes()
  const ilike = meLikes.like(id)
  const [comment, setComment] = React.useState('')

  const load = () =>
    api.sdk
      .postDetail({ id })
      .then((l) => {
        setPost(l.postById)
        return api.sdk.loadComment({ postId: id })
      })
      .then((c) => {
        setComments(c.comments)
        setStatus('loaded')
      })
      .then(() => api.sdk.postView({ id }))
      .catch((err) => {
        Logger.error('Unable to load posts', err)
        setStatus('error')
      })

  React.useEffect(() => {
    // Chargement initial puis rechargement toutes les 30 secondes
    // pour avoir les nouveaux commentaires
    let reloadTimeout: NodeJS.Timeout | undefined = undefined
    const reload = () => {
      reloadTimeout = setTimeout(reload, 30000)
      load()
    }
    reload()
    return () => {
      if (reloadTimeout) {
        clearTimeout(reloadTimeout)
      }
    }
  }, [id, ilike])

  if (status === 'loading') {
    return <div>Loading</div>
  }
  if (!post || status === 'error') {
    return <div>Error</div>
  }

  const addComment = () => {
    if (comment.trim().length === 0) {
      return
    }
    push('loading', "commentaire en cours d'ajout")
    api.sdk
      .postCreateComment({
        comment,
        postId: id,
      })
      .then(load)
      .then(() => push('success', 'Votre commentaire a bien été ajouté ! Merci !'))
      .catch((err) => {
        Logger.error('Unable to post comment', err)
        push('error', "Oups, erreur d'ajout du commentaire :(")
      })
  }

  return (
    <section className="w-full md:w-2/3 flex flex-col items-center px-3">
      <article className="w-full flex flex-col shadow my-4">
        {post.imagePath && (
          <img src={post.imagePath} style={{ maxHeight: 300, width: 'auto', height: 'auto', objectFit: 'contain' }} />
        )}
        <div className="bg-white flex flex-col justify-start p-6">
          <div className="flex flex-row">
            <p>
              {post.tags.map((t, i) => (
                <>
                  {i > 0 && <span>, </span>}
                  <span className="text-blue-700 text-sm font-bold uppercase pb-4">{t}</span>
                </>
              ))}
            </p>
            <p className="flex-1"></p>
            <p className="flex flex-row">
              <PostMetrics post={post} />
            </p>
          </div>
          <h1 className="text-3xl font-bold hover:text-gray-700 pb-4">{post.title}</h1>
          <a href="#" className="text-sm pb-8">
            Par{' '}
            <a href="#" className="font-semibold hover:text-gray-800">
              {post.user.fullname}
            </a>
            , {formatDistanceToNow(parseISO(post.statusDate), { addSuffix: true, locale: fr })}
          </a>
          <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </div>
      </article>

      <div className="bg-gray-300 p-2 w-full rounded font-semibold text-sm">
        <textarea
          className="w-full bg-white p-2"
          placeholder="Un commentaire ?"
          rows={3}
          onChange={(e) => setComment(e.target.value)}
        >
          {comment}
        </textarea>
        <div className="w-full text-right">
          <Button label="Commenter" onClick={addComment} />
        </div>
      </div>
      <span className="text-left pt-3 w-full">
        {post.nbComment === 0 ? 'Aucun commentaire. Soyez le premier !' : `${post.nbComment} commentaire(s)`}
      </span>
      {comments?.map((c) => (
        <div key={c.id} className="w-full text-left mt-3 bg-gray-300 p-2 rounded">
          <div className="text-cw font-semibold text-sm">
            {c.user.fullname}, {formatDistanceToNow(parseISO(c.creationDate), { addSuffix: true, locale: fr })} :{' '}
          </div>
          <div className="bg-gray-50 p-2 rounded text-sm">{c.content}</div>
        </div>
      ))}
    </section>
  )
}

export default ListPosts
